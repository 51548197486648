@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");

.App {
  text-align: center;
  background-image: url("bkg.jpg");
  background-position: top;
  background-size: cover;
  padding-bottom: 40px;
  height: 190vh;
}

.title {
  color: black;
  display: inline-block;
  text-shadow: 2px 2px 4px #f4f2f2;
  font-family: "Nanum Pen Script";
  font-weight: 800;
  font-size: 60px;
  filter: grayscale();
  margin: 20px;
}
h3 {
  color: black;
  font-size: 24px;
  text-shadow: 2px 2px 4px #f4f2f2;
  font-family: serif;
  font-size: 42px;
  margin: 0;
}
.click-below {
  font-size: 20px;
  color: black;
  font-family: serif;
  font-weight: bold;
  text-shadow: 4px 4px 5px #fffefe;
  margin-top: 35px;
}
.home-image {
  width: 38%;
  height: 38%;
  padding: 10px 10px 20px 10px;
  border: 1px solid #bfbfbf;
  background-color: white;
  box-shadow: 10px 10px 5px #727272;
  border-radius: 9px;
}
.ryNoah-image {
  width: 60%;
  padding: 6px 6px 10px 6px;
  border: 1px solid #bfbfbf;
  background-color: white;
  box-shadow: 10px 10px 5px #727272;
  border-radius: 9px;
}
.ry-image {
  width: 50%;
  padding: 10px 10px 20px 10px;
  border: 1px solid #bfbfbf;
  background-color: white;
  box-shadow: 10px 10px 5px #727272;
  border-radius: 9px;
}

.id {
  display: block;
  margin: 5px;
}
nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 500px;
}
.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-image-random-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.home-image-random-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.arrow {
  max-width: 50px;
  opacity: 70%;
  margin-top: 10px;
}
.one-selfie {
  display: flex;
  align-items: center;

  justify-content: center;
  gap: 35px;
}
.ryNoah-image {
  width: 40%;
}

a button,
.home-shuffle {
  font-size: 28px;
  text-transform: uppercase;
  border: 0px solid black;
  border-radius: 5px;
  opacity: 100%;
  box-shadow: 5px 5px 3px #5b5a5a;
  background: white;
  transition: all 0.2s;
  font-family: "Nanum Pen Script";
  font-size: 30px;
  opacity: 80%;
}
.shuffle {
  margin-top: 20px;
}
/* .link-button {
  width: 290px;
} */
button:hover,
.arrow:hover {
  transform: translateY(-5px);
}
.selfie-button {
  font-size: 18px;
  text-transform: uppercase;
  border: 0px solid black;
  border-radius: 6px;
  opacity: 100%;
  box-shadow: 5px 5px 3px #5b5a5a;
  background: white;
  transition: all 0.2s;
  margin-top: 15px;
  font-family: "Nanum Pen Script";
  font-weight: 500;
  font-size: 32px;
  max-width: 400px;
  opacity: 70%;
}
.return {
  text-shadow: 2px 2px 4px #f4f2f2;
  /* animation: animate 8.5s linear infinite; */
}

/* @keyframes animate {
  20% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
} */
